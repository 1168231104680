import React from 'react';

function eventsRow(props) {
	// return (
	// 	<div className='box row my-3'>
	// 		<div className='col-md-3 date blue-bor py-2 d-flex flex-column align-items-center justify-content-center'>
	// 			{/* <h2>22nd</h2> */}
	// 			<h5>{props.date}</h5>
	// 		</div>
	// 		<div className='col-md-9 py-2 blue-bor content'>
	// 			<div className='row'>
	// 				<div className='col-md-8 blue-bor py-2'>
	// 					<h4>{props.name}</h4>
	// 					<p>{props.short_description}</p>
	// 				</div>
	// 				<div className='col-md-4 blue-bor d-flex align-items-center justify-content-center'>
	// 					<button>
	// 						<a className='event-link' href={`/getevent/${props.linkclub}/${props.id}`}>
	// 							Learn More
	// 						</a>
	// 					</button>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
}

export default eventsRow;