import React from 'react'
import poster from '../../images/pac_poster.png'
import './PacOverlay.css'

export default function PacOverlay() {

    window.onclick = function(event) {
        if (event.target === document.querySelector('.pac_overlay')) {
            document.querySelector('.pac_overlay').style.display = 'none'
        }
    }

    return (
        <div className="pac_overlay">
            <div className="pac_overlay__content">
                <a href='https://caic.iitd.ac.in/pac'>
                    <img src={poster} alt="Pac poster" className="pac_overlay__poster"/>
                </a>
            </div>
        </div>
    )
}
